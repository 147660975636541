var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-manage" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-lg-5" }, [
          _c("h1", [_vm._v("パスワード変更")]),
          _vm.messages.length > 0 && !_vm.errMessage
            ? _c(
                "ul",
                { staticClass: "alert alert-danger" },
                _vm._l(_vm.messages, function(message) {
                  return _c("li", [_vm._v(_vm._s(message))])
                }),
                0
              )
            : _vm._e(),
          _vm.errMessage
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v("システムエラーが発生しました。"),
                _c(
                  "div",
                  [
                    _vm._v(
                      "しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが"
                    ),
                    _c(
                      "router-link",
                      { attrs: { to: "/inquiry_form/input" } },
                      [_vm._v("こちら")]
                    ),
                    _vm._v("よりをお問い合わせください。")
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "form",
            {
              staticClass: "needs-validation",
              attrs: { novalidate: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.exec($event)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "password1" } }, [
                    _vm._v("現在のパスワード")
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password1,
                        expression: "password1"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "password1",
                      type: "password",
                      minlength: "8",
                      maxlength: "16",
                      placeholder: "パスワード(8~16文字の半角英数字)",
                      required: ""
                    },
                    domProps: { value: _vm.password1 },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.password1 = $event.target.value
                      }
                    }
                  }),
                  _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v("パスワードを8~16文字で入力してください")
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.textCount1) + "/16")]),
                  _c(
                    "router-link",
                    { attrs: { to: "/user/password_reset/reset" } },
                    [_vm._v("パスワード不明の場合")]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "password2" } }, [
                  _vm._v("新しいパスワード")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password2,
                      expression: "password2"
                    }
                  ],
                  ref: "password2",
                  staticClass: "form-control",
                  attrs: {
                    id: "password2",
                    type: "password",
                    minlength: "8",
                    maxlength: "16",
                    placeholder: "パスワード(8~16文字の半角英数字)",
                    required: ""
                  },
                  domProps: { value: _vm.password2 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password2 = $event.target.value
                    }
                  }
                }),
                _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v("パスワードを8~16文字で入力してください")
                ]),
                _c("div", [_vm._v(_vm._s(_vm.textCount2) + "/16")])
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "password3" } }, [
                  _vm._v("新しいパスワード（確認）")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password3,
                      expression: "password3"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "password3",
                    type: "password",
                    minlength: "8",
                    maxlength: "16",
                    placeholder: "パスワード(確認)",
                    required: ""
                  },
                  domProps: { value: _vm.password3 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password3 = $event.target.value
                    }
                  }
                }),
                _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v("パスワードを8~16文字で入力してください")
                ]),
                _c("div", [_vm._v(_vm._s(_vm.textCount3) + "/16")])
              ]),
              _c("input", {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "submit",
                  value: "パスワードを変更する",
                  disabled: _vm.isDisabled
                }
              })
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }