<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center
        .col-lg-5
          h1 パスワード変更
          ul.alert.alert-danger(v-if="messages.length > 0 && !errMessage")
            li(v-for="message in messages") {{ message }}
          .alert.alert-danger(v-if="errMessage") システムエラーが発生しました。
            div しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが
              router-link(to="/inquiry_form/input") こちら
              |よりをお問い合わせください。
          form.needs-validation(@submit.prevent="exec" novalidate)
            .form-group
              label(for="password1") 現在のパスワード
              input#password1.form-control(type="password" minlength='8' maxlength='16' placeholder="パスワード(8~16文字の半角英数字)" v-model="password1" required)
              .invalid-feedback パスワードを8~16文字で入力してください
              div {{ textCount1 }}/16
              router-link(to="/user/password_reset/reset") パスワード不明の場合
            .form-group
              label(for="password2") 新しいパスワード
              input#password2.form-control(type="password" ref="password2" minlength='8' maxlength='16' placeholder="パスワード(8~16文字の半角英数字)" v-model="password2" required)
              .invalid-feedback パスワードを8~16文字で入力してください
              div {{ textCount2 }}/16
            .form-group
              label(for="password3") 新しいパスワード（確認）
              input#password3.form-control(type="password" minlength='8' maxlength='16' placeholder="パスワード(確認)" v-model="password3" required)
              .invalid-feedback パスワードを8~16文字で入力してください
              div {{ textCount3 }}/16
            input.btn.btn-primary(type="submit" value="パスワードを変更する" v-bind:disabled="isDisabled")
</template>

<script>
import axios from 'axios'

import {
  Config,
  CognitoIdentityCredentials
} from 'aws-sdk'
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute
} from 'amazon-cognito-identity-js'

export default {
  data() {
    return {
      messages: [],
      password1: '',
      password2: '',
      password3: '',
      isDisabled: false,
      errMessage: false
    }
  },
  mounted() {
    //サインインしていない場合
    this.$user.cognito.getUserAttributes((err, result) => {
      if (err) {
        this.$router.push({ name: 'login', query: { url:this.$route.path }}) //ページ移動
        return
      }
    })
  },
  methods: {
    
    exec(e) {
      this.isDisabled = true

      this.messages = []

      this.errMessage = false

      //パスワード一致チェック
      if(this.password1 && this.password1 == this.password2) {
        this.messages.push('パスワードが変更前と同じです')
      }else if ( this.password2 != this.password3 ) {
        this.messages.push('パスワードが一致しません　もう一度入力し直してください')
      }else if (this.password2.trim().length == 0 || this.password3.trim().length == 0){
        //パスワードがスペースのみの場合はAPIでエラーになるため、事前にチェックする
        this.messages.push('パスワードは空欄、又はスペースのみの入力はできません。')
      }

      //エラー時処理終了
      if(this.messages.length > 0) {
        //入力中のパスワードをクリア
        this.password2 = ''
        this.password3 = ''
        this.isDisabled = false
        return
      }

      //エラー表示
      if (e.target.checkValidity() === false) {
        e.target.classList.add('was-validated')
        e.stopPropagation()
        this.isDisabled = false
        return
      }

      //パスワード変更
      this.$user.cognito.changePassword(this.password1, this.password2, (err, result) => {
        if (err) {
          if(err.code == 'InvalidPasswordException') {
            err.message = 'パスワードは大小英数字８文字以上で入力してください。'
            //入力中のパスワードをクリア
            this.password2 = ''
            this.password3 = ''
          }else if(err.code == 'NotAuthorizedException') {
            err.message = '現在のパスワードが正しく入力されていません。'
            this.password1 = ''
          }else if(err.code == 'LimitExceededException' || err.code == 'TooManyRequestsException') {
            err.message = 'しばらく時間をおいてから再度お試しください。'
          }else { 
            this.errMessage = true
          }
          this.messages.push(err.message)
          this.isDisabled = false
          return
        }
        //パスワード更新
        const url = process.env.VUE_APP_API_GATEWAY + '/api-user'
        const params = {
          pw: this.password2
        }
        //内容送信
        axios.patch(url, params, {
          headers: {
            'Authorization': this.$user.idToken
          }
        })
          .then(res => {
            this.$router.push('/user/password/complete')
            this.isDisabled = false
          })
          .catch(err => {
          console.log(err)
          })
      })
    }
  },
  computed: {
    textCount1() { return this.password1.length },
    textCount2() { return this.password2.length },
    textCount3() { return this.password3.length }
  }
}
</script>